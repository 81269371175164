import React from 'react'
import './loader.css';


const Loader = () => {
  return (
    <div>
      <div class="spinner">
  <div></div>   
  <div></div>    
  <div></div>    
  <div></div>    
</div>
    </div>
  )
}

export default Loader
