import React from 'react'
import './industry.css';
import Software from '../Home/Software';
import Dimage from '../Home/Dimage';



const Industry = () => {
  return (
    <div>
                   <section>
                    
                      <div className=' ind-container '>

                             <div className='ind-image'>

                            <img src='https://multi-techno.com/wp-content/uploads/elementor/thumbs/for-Yours-Support-3-1024x384-1-pyez9mnobf4jn5fae2antjkr09ckdcmdwqpylijjeo.webp' alt=''/>

                             </div>



                      </div>
            
                    
                    </section>      





                    <section class="elementor-section ">
						<div class="elementor-container ">
					<div class="elementor-column  ">
			
            
            
            
            <div class="elementor-widget-wrap ">
	<div class="elementor-element  ">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading">Industries we are serving</h3>		</div>
				</div>
					</div>
		</div>
							</div>
		</section>


       


                <Dimage/>



                 <Software/>





    </div>
  )
}

export default Industry
